/* *,
*::before,
*::after {
  box-sizing: inherit ;
} */
html {
    box-sizing: border-box;
}
html,
.body {
    padding: 0;
    margin: 0;
    /* background: url(./img/repeated_bg.png) !important; */
    background-color: lightgrey;
    min-height: 100%;
    font-family: 'Quicksand', Helvetica, sans-serif;
}
.dropbtn {
    /* background-color: #4CAF50; */
    color: black;
    padding: 16px;
    font-size: 16px;
    border: none;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #ddd;
}

/* Counter for 5 minutes Expire*/
.show-counter {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
}
.show-counter .countdown {
    line-height: 1.25rem;
    padding: 0 0.75rem 0 0.75rem;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.show-counter .countdown.warning {
    color: #0d6efd;
}
.show-counter .countdown.success {
    color: #198754;
}

.show-counter .countdown > p {
    margin: 0;
}

.show-counter .countdown > span {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 0.25rem;
}

/*For power bi embed*/

.embed_container {
    width: 100%;
    height: 72vh;
}

#onDeleted  {
 background-color: yellow;
}

.session-out-message {
    display: flex;
    justify-content: center;
    align-content: center;
    font-weight: bold;
}

